@use "../../styles/sass/base/colors" as *;
@use "../../styles/sass/base/typography" as *;
@use "../../styles/sass/layouts/flexbox" as *;
@use "../../styles/sass/layouts/_utilities" as *;
@use "../../styles/sass/abstracts/variables" as var;

.case-page {
	@apply bg-neutral-pure-50;

	text-align: center;

	.other-cases {
		@apply flex--column center-content p-[5%] bg-yellowish-high-50;

		.other-works-division-line {
			@apply w-[80%] border-purplish-pure-500 border my-6 bg-yellowish-high-50;
		}

		.other-cases-title {
			@apply text-[1.75rem] font-title mb-4 text-purplish-pure-500 font-bold;

			align-self: start;
			margin-left: 10%;
		}
	}
}
