@use "../../styles/sass/base/typography" as *;
@use "../../styles/sass/layouts/flexbox" as *;
@use "../../styles/sass/abstracts/variables" as var;

.case-section {
	@apply py-16 px-8 max-w-5xl mx-auto;

	.title {
		@apply text-center font-title text-4xl text-purplish-low-950 mb-4;
	}

	.slogan {
		@apply text-center font-body text-lg text-purplish-pure-500 mb-4;
	}

	.client-year {
		@apply text-center text-purplish-low-950/50 font-body uppercase text-sm mb-14;
	}

	.brand {
		@apply my-[4rem] center-content;
	}

	.division-line {
		@apply border-yellowish-pure-500 border-2 my-6;
	}

	.definitions {
		@apply gap-10 w-full text-center center-content flex--column lg:px-[26%] md:px-[26%] text-purplish-low-950;

		justify-content: center;

		.definition-title {
			@apply text-[1.75rem] font-title mb-4;
		}

		.definition-description {
			@apply font-body mb-6;
		}
	}

	.video {
		@apply gap-5 flex--column center-content;

		.video-caption {
			@apply text-sm rounded-[2rem] w-[fit-content] px-[1.75rem] py-[0.25rem] bg-yellowish-pure-500;
		}
	}
}

footer {
	@apply pb-[2rem] center-content;

	background: linear-gradient(
		to bottom,
		var.$neutral-pure-50 0%,
		var.$neutral-pure-50 50%,
		var.$yellowish-high-50 50%,
		var.$yellowish-high-50 100%
	);

	> iframe {
		width: 25rem;
	}
}
