@use "../../styles/sass/abstracts/variables" as var;

.navbar {
	@apply w-full;
	padding: 2rem 5rem;
	z-index: 10;
	transition: background-color 0.3s ease;

	.navbar-desktop {
		display: flex;
		justify-content: space-between;

		.links-wrapper {
			list-style: none;
			display: flex;
			gap: 3.5vw;
		}

		.language-switch {
			color: white;
			align-content: center;
		}
	}

	.navbar-mobile {
		display: none;
	}

	@media (max-width: 947px) {
		padding: 1rem 2rem;

		.navbar-desktop {
			display: none;
		}

		.navbar-mobile {
			margin-top: 2rem;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}
}
