@use "../../styles/sass/abstracts/variables" as var;

.language-switch-dropdown {
	align-self: center;
	position: relative;
	display: inline-block;
	font-family: "Inter", Arial;

	.chip-button {
		background-color: transparent;
		border: 1px solid currentColor;
		border-radius: 9999px;
		padding: 0.25rem 1rem;
		cursor: pointer;
		font-size: 0.875rem;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		gap: 0.5rem;

		.arrow {
			font-size: 0.6rem;
		}
	}

	.dropdown-menu {
		position: absolute;
		top: 110%;
		left: 0;
		background-color: white;
		color: black;
		border: 1px solid #ccc;
		border-radius: 0.5rem;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
		z-index: 999;
		padding: 0.25rem 0;
		width: 100%;
		font-size: 0.875rem;

		.dropdown-options {
			padding: 0.5rem 1rem;
			cursor: pointer;

			&:hover {
				background-color: var.$grayish-high-50;
			}
		}
	}
}
