@use "../../styles/sass/abstracts/variables" as var;

.scroll-next {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	cursor: pointer;
	place-self: anchor-center;

	.circle {
		background-color: transparent;
		border-radius: 50%;
		padding: 0.5rem;
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
		transition: transform 0.2s ease;
		animation: bounce 2s infinite;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2rem;
		height: 2rem;

		svg {
			width: 1rem;
			height: 1rem;
		}
	}

	.circle:hover {
		transform: translateY(4px);
	}

	.purple {
		@apply border border-neutral-pure-50;

		svg {
			color: var.$neutral-pure-50;
		}
	}

	.white {
		@apply border-purplish-pure-500 bg-purplish-pure-500;

		svg {
			color: var.$neutral-pure-50;
		}
	}
}

@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(8px);
	}
	60% {
		transform: translateY(4px);
	}
}
