@use "../../styles/sass/base/colors" as *;
@use "../../styles/sass/base/typography" as *;
@use "../../styles/sass/layouts/flexbox" as *;
@use "../../styles/sass/layouts/_utilities" as *;
@use "../../styles/sass/abstracts/variables" as var;

.works-section {
	@apply h-[98&] py-[5%] flex--column flex-justify--center;

	@media (max-width: 1200px) {
		height: fit-content;
		padding-top: 20%;
	}

	.page-title {
		@apply color--purplish-pure-500 font-title flex;
		align-self: center;
		align-items: center;
		gap: 1rem;
		margin-bottom: 1rem;

		svg {
			rotate: 90deg;
		}

		.title {
			@apply color--purplish-pure-500 font-title flex;
			font-weight: 800;
			font-size: var.$fonts-80;
			padding-bottom: 2rem;

			@media (max-width: 1900px) {
				font-size: var.$fonts-64;
			}

			@media (max-width: 700px) {
				font-size: var.$fonts-48;
				padding-bottom: 1rem;
			}
		}
	}

	.scroll-next {
		margin-top: 2rem;
		padding-bottom: 1rem;
	}
}

.works-carousel {
	align-self: center;
	width: 80% !important;

	.works-card {
		@apply overflow-hidden cursor--pointer;

		.work-texts {
			@apply text-center center-content absolute flex flex-col gap-3 inset-0 p-4 text-neutral-pure-50 p-1;

			align-self: center;
			font-weight: 300;
			z-index: 10;

			.work-title {
				@apply text-[2rem] md:text-[1.75rem] sm:text-[1.5rem] font-title;
			}

			.work-description {
				@apply text-[0.75rem] font-body;
			}
		}

		.work-image {
			@apply object-cover relative;

			> svg {
				@apply rounded-[2.5rem] w-full h-full;
			}
		}

		.work-overlay {
			@apply rounded-[2.5rem] inset-0 bg-purplish-pure-500/0 z-10 absolute;
			transition: background-color 0.3s ease;
		}

		&:hover .work-overlay {
			@apply bg-purplish-low-950/60;
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: none;
	}

	.swiper-wrapper {
		@media (max-width: 640px) {
			margin-bottom: 10%;
		}
	}

	.swiper-pagination-bullet {
		background-color: var.$purplish-pure-500;
	}
}
