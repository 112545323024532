@use "../../styles/sass/base/colors" as *;
@use "../../styles/sass/base/typography" as *;
@use "../../styles/sass/layouts/flexbox" as *;
@use "../../styles/sass/abstracts/variables" as var;

.about-section {
	@apply min-h-[98%] px-[10%] py-[8%] flex--column flex-justify--center;

	@media (max-width: 1200px) {
		height: fit-content;
		padding: 20% 2rem 0;
		line-height: 5rem;
	}

	@media (max-width: 700px) {
		height: fit-content;
		padding: 20% 2rem 0;
		line-height: 5rem;
	}
}

.about-section h1 {
	font-size: 14em;
	line-height: 8rem;
	margin: 0;

	@media (max-width: 1299px) {
		font-size: 10em;
		line-height: 1;
	}

	@media (max-width: 768px) {
		font-size: 5rem;
	}
}

.about-section p {
	@apply color--neutral-pure-500;
	text-align: start;
	margin-top: 20px;
	float: inline-start;
	font-size: var.$fonts-18;
	line-height: 1.5rem;

	@media (max-width: 768px) {
		font-size: 1.2rem;
		line-height: 2.5rem;
	}
}

.about-section .first-line {
	@apply font-title;

	align-items: baseline;
	color: var.$purplish-pure-500;
	display: flex;
	font-size: var.$fonts-170;
	gap: 1rem;

	@media (max-width: 1299px) {
		font-size: var.$fonts-96;
		padding-top: 5%;
	}

	@media (max-width: 768px) {
		font-size: 5rem;
		padding-top: 5%;
	}
}

.about-section .presentation {
	@apply max-w-[36rem] gap-[4rem] flex--column;

	@media (max-width: 1299px) {
		gap: 0rem;
	}
}

.about-section .about-wrapper {
	@apply w-full gap-[2rem] px-[15%] flex--row flex-justify--between;

	@media (max-width: 1299px) {
		@apply flex--column-reverse;
		height: fit-content;
		gap: 0.5rem;
		align-items: center;
	}

	@media (max-width: 700px) {
		@apply px-0;
	}
}

.about-section .image {
	height: 80%;

	> svg {
		border-radius: 68px;
	}

	@media (max-width: 507px) {
		> svg {
			border-radius: 0px;
		}
	}
}

.about-section .scroll-next {
	margin-top: 5vh;
}
