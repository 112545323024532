@font-face {
	font-family: "Kaisei Decol";
	src: url("~assets/fonts/KaiseiDecol-Regular.woff2") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Kaisei Decol";
	src: url("~assets/fonts/KaiseiDecol-Medium.woff2") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Kaisei Decol";
	src: url("~assets/fonts/KaiseiDecol-Bold.woff2") format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-Regular.woff2") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-Bold.woff2") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-Black.woff2") format("truetype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-BlackItalic.woff2") format("truetype");
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-ExtraBold.woff2") format("truetype");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-ExtraBoldItalic.woff2") format("truetype");
	font-weight: 800;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-ExtraLight.woff2") format("truetype");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-ExtraLightItalic.woff2") format("truetype");
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-Italic.woff2") format("truetype");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-Light.woff2") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-LightItalic.woff2") format("truetype");
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-Medium.woff2") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-MediumItalic.woff2") format("truetype");
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-SemiBold.woff2") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-SemiBoldItalic.woff2") format("truetype");
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-Thin.woff2") format("truetype");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Inter";
	src: url("~assets/fonts/Inter_18pt-ThinItalic.woff2") format("truetype");
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}
