:root:has(.loading-screen) {
	overflow: hidden;
}

.loading-screen {
	@apply bg-purplish-pure-500 overflow-y-hidden;

	position: fixed;
	inset: 0;
	z-index: 50;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: visible;
}

.loading-icon {
	animation-fill-mode: forwards;
}

.pulse {
	animation: pulseSpinAnim 1.5s ease-in-out infinite;
}

@keyframes pulseSpinAnim {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

.move-to-top {
	position: absolute;
	transform: rotate(29deg);
	animation: moveUp 1s ease-in-out forwards;
}
